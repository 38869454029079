import * as Chart from "chart.js";

const barChartColors = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"];

// Creates a chart.js chart using provided results array
export const createChart = (results: Array<String>, elementID: string, categories: string[]) => {

  const canvas = document.getElementById(elementID) as HTMLCanvasElement;
  const ctx = canvas.getContext("2d");
  const barColors = Object.assign([], barChartColors);
  barColors.splice(categories.length);

  new Chart(ctx, {
      type: 'bar',
      data: {
        labels: categories,
        datasets: [{
          label: "Score",
          data: results,
          backgroundColor: barColors,
          borderColor: barColors,
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMax: 7
            }
          }]
        },
        legend: false,

      }
  });
}