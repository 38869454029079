import * as React from "react";
import { get, post, del } from "superagent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export class Settings extends React.Component<any, any> {
  
  state = {
    test: null,
    error: null,
    saved: false,
    dialogOpen: false
  }

  constructor(props) {
    super(props);
    this.getTest = this.getTest.bind(this);
    this.updateText = this.updateText.bind(this);
    this.updateTest = this.updateTest.bind(this);
    this.deleteTest = this.deleteTest.bind(this);
    this.updateEnabled = this.updateEnabled.bind(this);
    this.updateShareable = this.updateShareable.bind(this);
  }

  async getTest() {
    // Remove hash # from string
    await get(`/test/get`)
    .query({testId: this.props.match.params.id})
    .withCredentials()
    .then(res => {
      this.setState({test: res.body});
    })
    .catch(err => {
      this.setState({error: "There was an error getting test data."});
    });
  }

  // Updates a text item in test
  async updateText(key, e) {
    let newTest = Object.assign({}, this.state.test);
    newTest[key] = e.target.value;
    this.setState({test: newTest});
  }

  // Sends the updated test info to be saved in the database
  async updateTest() {
    await post(`/test/update`)
    .send({
      test: this.state.test,
      testId: this.props.match.params.id,
    })
    .withCredentials()
    .then(res => {
      this.setState({saved: true, error: null});
    })
    .catch(err => {
      this.setState({
        proceedReset: false, 
        error: err.response.text ? err.response.text : "There was an error updating the test."
      });
    });
  }

  // Updates the 'enabled' value from the checkbox
  updateEnabled(e) {
    this.setState({test: {...this.state.test, enabled: e.target.checked}});
  }

  // Updates the 'shareable' value from the checkbox
  updateShareable(e) {
    this.setState({test: {...this.state.test, shareable: e.target.checked}});
  }

  // Deletes the test and related questions/answers
  async deleteTest() {
    const testId = this.state.test.id;
    await del("/test/delete").query({testId}).withCredentials()
    .then(res => {
      // Send back to homepage
      window.location.href = "/";
    })
    .catch(err => {
      this.setState({error: "An eror occured."});
    });
  }

  componentDidMount() {
    this.getTest();
  }

  componentDidUpdate(prevProps, prevState) {
    // After 2 seconds, hide the save message
    if (!prevState.saved && this.state.saved) {
      setTimeout(() => {
        this.setState({saved: false});
      }, 2000)
    }
  }

  render() {
    const { error, test, saved, dialogOpen } = this.state;

    if (test) {
      return (
        <div id="test-settings">
          <p>
            <b>Title:</b><br/>
            <input 
              value={test.title}
              onChange={e => this.updateText("title", e)}
              />
          </p>
          <p>
            <b>Description:</b><br/>
            <textarea 
              value={test.description} 
              rows={5} 
              cols={60} 
              onChange={e => this.updateText("description", e)}/>
          </p>
          <p>
            <b>Enabled</b> 
            <input 
              type="checkbox"
              defaultChecked={test.enabled}
              onClick={e => this.updateEnabled(e)}
              /><br/>
              <i>When not checked, this test will be hidden from students.</i>
          </p>
          <p>
            <b>Make Test Shareable</b> 
            <input 
              type="checkbox"
              defaultChecked={test.shareable}
              onClick={e => this.updateShareable(e)}
              /><br/>
              <i>This will allow the test to be accessible in any course (even courses that aren't yours).</i>
          </p>
          <p>
            <a href="#" onClick={e => this.setState({dialogOpen: true})}>Delete Test</a>
          </p>
          <div className="dialog" style={{display: dialogOpen ? "initial" : "none"}}>
            <p>
              Are you sure you want to delete this test? Questions and student answers will also be deleted. This action cannot be undone.
            </p>
            <div className="buttons">
              <button 
                className="btn danger"
                onClick={e => this.deleteTest()}>
                Delete
                </button>
              <button 
                className="btn btn-detault"
                onClick={e => this.setState({dialogOpen: false})}>
                Cancel
              </button>
            </div>
          </div>
          <button 
            className="btn btn-default" 
            onClick={e => this.updateTest()}
            style={{display: dialogOpen ? "none" : "initial"}}>
            Save
          </button>
          <p>
          <span className={`success-message ${saved ? "" : "hidden"}`}>
            Test successfully updated.
          </span> 
          </p>
          {error ? <div className="error">{error}</div> : null}
        </div> 
      );
    }

    else {
      return (
        <div className="error">
          There was an error getting test data.
        </div>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};


export default withRouter(connect(mapStateToProps)(Settings));
