import { merge } from "lodash";

export const UPDATE_CURRENT_QUESTION = "UPDATE_CURRENT_QUESTION";

export let updateCurrentQuestion = (options: {questionId: number}) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, {
      type: UPDATE_CURRENT_QUESTION,
      questionId: options.questionId
    }));
  };
};
