import * as React from "react";
import { withRouter, NavLink } from "react-router-dom";

export class InstructorNav extends React.Component<any, any> {
  
  render() {
    const testId = this.props.match.params.id;
    return (
      <div className="nav">
        <ul>
          <li>
            <NavLink exact to={`/`} activeClassName="active">
              Home
            </NavLink>
            <NavLink to={`/test/${testId}/settings`} activeClassName="active">
              Settings
            </NavLink>
            <NavLink to={`/test/${testId}/questions`} activeclassname="active">
              Questions
            </NavLink>
            <NavLink to={`/test/${testId}/categories`} activeclassname="active">
              Categories
            </NavLink>
            <NavLink to={`/test/${testId}/results`} activeclassname="active">
              Results
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(InstructorNav);
