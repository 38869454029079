import { merge } from "lodash";
import { get } from "superagent";

export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_DISPLAY_RESULT = "SET_DISPLAY_RESULT";

export let setDisplayResult = (questionId: Number) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, {
      type: SET_DISPLAY_RESULT,
      showResults: true
    }));
  };
};

export let setQuestions = (options: {testId: string}) => {
  return async (dispatch, getState) => {
    const now = new Date().getTime().toString();
    const questions = await get(`/question/all`)
      .withCredentials()
      .query({testId: options.testId}).then(res => {
        return res.body;
      }).catch(err => {
        console.log(err);
      });
    await dispatch(merge({}, {
      type: SET_QUESTIONS,
      questions,
      showResults: false,
      loadedAt: now
    }));
  };
};
