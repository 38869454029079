import * as React from "react";
import { connect } from "react-redux";
import { get } from "superagent";
import { NavLink } from "react-router-dom";

import Question from "./question";
import StudentNav from  "./studentNav";
import { setQuestions } from "../../actions/questions";
import { setAnswers } from "../../actions/answer";
import { updateCurrentQuestion } from "../../actions/currentQuestion";

class Test extends React.Component<any, any> {

  state = {
    test: null
  }

  constructor(props) {
    super(props);
    this.getTest = this.getTest.bind(this);
  }

  // Gets test & answers (if any) from DB
  async getTest() {
    await get(`/test/get`)
    .query({testId: this.props.match.params.id})
    .withCredentials()
    .then(res => {

      // redirect to results page if test was completed
      if (res.body.userTest && res.body.userTest.submitted) {
        window.location.href = `/test/${this.props.match.params.id}/results`;
        return;
      }

      this.setState({test: res.body.test});
      // Import answers from the database into the redux state
      this.props.dispatch(setAnswers({
        answers: JSON.parse(res.body.userTest.answers), 
        submitted: res.body.userTest.submitted
      }));
    })
    .catch(err => {
      this.setState({error: "There was an error getting test data."});
    });
  }

  componentDidMount() {
    this.getTest();  

    // Set questions in Redux/state
    this.props.dispatch(setQuestions({testId: this.props.match.params.id}));
  }

  render() {
    const {currentQuestion, totalQuestions, dispatch} = this.props;
    const { test } = this.state;

    return (
      <div>
        <div className="nav">
          <ul>
            <li>
              <NavLink exact to={`/`} activeClassName="active">
                <i className="fa fa-arrow-left"/> &nbsp;View All Tests
              </NavLink>
              <a 
                className={currentQuestion === -1 ? "active" : ""}
                onClick={e => dispatch(updateCurrentQuestion({questionId: -1}))}>
                Test Instructions
              </a>
            </li>
          </ul>
        </div>
        <div 
          className={`question-count ${currentQuestion < 0 ? "hidden" : ""}`}>
          Question {currentQuestion + 1}/{totalQuestions}
        </div>
        {test ? 
          currentQuestion === -1 ? 
            <div>
              <h2 style={{marginTop: "20px"}}>{test.title}</h2>
              <p>{test.description}</p>
              <p>This test contains <b>{test.numQuestions}</b> questions.</p>
              <button 
                className="btn btn-default"
                onClick={e => dispatch(updateCurrentQuestion({questionId: 0}))}>
                Start &nbsp;
                <i className="fa fa-arrow-right"/>
              </button>
            </div>
          : 
          
          <div>
            <Question/>
            <StudentNav/>
          </div>
        : null }

      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let { 
    currentQuestion, 
    questions: { totalQuestions } 
  } = state;
  return { currentQuestion, totalQuestions };
};

export default connect(mapStateToProps)(Test);
