import * as React from "react";
import { get, post } from "superagent";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

export class StudentView extends React.Component<any, any> {
  
  _importsWindowEl;

  state = {
    tests: [],
    completedTests: {},
    shareableTests: [],
    error: false,
    showingImports: false
  }

  constructor(props) {
    super(props);
    this.getAllTests = this.getAllTests.bind(this);
  }

  // Makes a call to the test API to get an array of all tests
  async getAllTests() {
    // Get all tests for this course
    await get(`/test/all`)
    .withCredentials()
    .then(res => {
      this.setState({tests: res.body.tests, completedTests: res.body.userTests});
    })
    .catch(err => {
      console.log(err);
      this.setState({error: "There was an error getting tests for this course."});
    });
  }

  componentDidMount() {
    this.getAllTests();
  }

  render() {
    const { tests, completedTests } = this.state;

    return (
      <div id="student-view">

        <h1>Your Self-Tests</h1>
        <p>Select a test below to take it, or view your results.</p>
        {tests && tests.length > 0 ? 
          <ul className="tests">
            {tests.map((test, i) => {
              return (
                <Link key={i} to={completedTests[test.id] ? `test/${test.id}/results` : `/test/${test.id}`}>
                  <li>
                    <h3>{test.title}</h3>
                    <p>{test.description}</p>
                    <p>Questions: {test.numQuestions}</p>
                    <span>
                      Status:&nbsp;
                      {completedTests[test.id] ? 
                        <span className="enabled"> 
                          Completed <i className="fa fa-check" aria-hidden="true"></i>
                        </span> : 
                        <span> Not Completed</span>
                      }  
                    </span>                
                  </li>
                </Link>
              );
            })}
          </ul> 
        : <p style={{fontWeight: "bold"}}>You have no tests at this time.</p>}
 
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};


export default withRouter(connect(mapStateToProps)(StudentView));
