import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get, post } from "superagent";

import { createChart } from "../../shared/helpers";

export class InstructorResults extends React.Component<any, any> {

  state = {
    error: null,
    totalResults: 0,
    dialogOpen: false,
    resetSuccess: false,
  }

  // Makes call to API to reset all results for the current test
  async deleteResults() {
    await post("/user-test/delete/all").query({testId: this.props.match.params.id})
    .then(res => {
      this.setState({resetSuccess: true, dialogOpen: false});
    }).catch(err => {
      this.setState({
        error: "There was an error resetting results. Reset unsuccessful.",
        dialogOpen: false
      });
    });
  }

  async componentDidMount() {
    // Get all test results
    await get(`/user-test/results/all`)
    .query({testId: this.props.match.params.id})
    .then((res) => {
      this.setState({totalResults: res.body.totalResults});
      createChart(res.body.scores, "total-results", res.body.categoryNames);
    })
    .catch((err) => {
      this.setState({error: "There was an error getting all test results."});
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // Hide error message after 5 seconds
    if (!prevState.error && this.state.error) {
      setTimeout(() => {
        this.setState({error: null});
      }, 4000);
    }
    // Hide success message after 5 seconds
    if (!prevState.resetSuccess && this.state.resetSuccess) {
      setTimeout(() => {
        this.setState({resetSuccess: false});
      }, 4000)
    }
  }

  render() {
    const { error, totalResults, dialogOpen, resetSuccess } = this.state;
    console.log("reset success", resetSuccess)
    return (
      <div className={"result-container"}> 
        <div style={{display: totalResults > 0 ? "block" : "none"}}>
          <h2 
            className={"result-text"} 
            style={{marginTop: "0px", marginBottom: "20px"}}>
            Average Student Results
          </h2>
          <span style={{marginBottom: "20px", display: "block"}}>
            <b>Total number of results:</b> {totalResults}
          </span>
          <canvas id="total-results" style={{maxWidth: "700px"}}></canvas>
          <br/><br/>
          {!dialogOpen ? 
            <button 
              onClick={e => this.setState({dialogOpen: true})} 
              className="btn btn-default">
              Reset results
            </button> : null}
          
        </div>
        
        <div style={{
          display: totalResults === 0 ? "block" : "none",
          marginTop: "30px"
        }}>
          There are no results to display yet.
        </div>
        
        {dialogOpen ? 
          <div className="dialog">
            <span className="warning-text">
              Are you sure you want to reset results? This will delete all student answers for this test.
            </span>
            <br/>
            <button onClick={() => this.setState({dialogOpen: false})}>Cancel</button>
            <button 
              className="green" 
              onClick={e => this.deleteResults()}>
              Continue
            </button>
          </div>
        : null}

        <span className={`warning-text ${error ? "" : "hidden"}`} style={{marginTop: "20px"}}>
          {error}
        </span>

        <span 
          style={{marginTop: "20px"}} 
          className={`success-message ${resetSuccess ? "" : "hidden"}`}>
          All answers have been successfully deleted.
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { currentQuestion, answers, questions } = state;
  return {
    currentQuestion,
    answers,
    questions
  };
};

export default withRouter(connect(mapStateToProps)(InstructorResults));
