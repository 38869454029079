import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get, post } from "superagent";

import SortableList from "./dragAndDrop";

const arrayMove = require("array-move");

export class DragAndDrop extends React.Component<any, any> {
  state = {
    questions: [],
    newQuestion: "",
    error: null,
    currentQuestion: {text: "", id: 0, index: null},
    dialog: {open: false, text: "", name: ""},
    saved: false,
    undone: false,
    categories: []
  }

  constructor(props) {
    super(props)
    this.handleSetCurrentQuestion = this.handleSetCurrentQuestion.bind(this);
    this.handleCancelUpdate = this.handleCancelUpdate.bind(this);
    this.handleUpdateQuestionText = this.handleUpdateQuestionText.bind(this);
    this.handleSaveQuestionText = this.handleSaveQuestionText.bind(this);
    this.handleDeleteQuestion = this.handleDeleteQuestion.bind(this);
    this.handleUpdateQuestionReverse = this.handleUpdateQuestionReverse.bind(this);
    this.handleUpdateQuestionCategory = this.handleUpdateQuestionCategory.bind(this);
    this.addQuestion = this.addQuestion.bind(this);
    this.handleDeleteAndSave = this.handleDeleteAndSave.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
  }

  // Handles what happens when an item is done sorting (dropped)
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({questions}) => ({
      questions: arrayMove(questions, oldIndex, newIndex),
    }));
  };

  // Gets questions from DB
  async getQuestions() {
    await get(`/question/all`)
    .withCredentials()
    .query({testId: this.props.match.params.id})
    .then(res => {
      if (res.status === 200) {
        this.setState({questions: res.body});        
      }
    })
    .catch(err => {
      this.setState({isAuthenticated: false, error: err});
    });
  };

  // Gets questions from DB
  async getCategories() {
    await get(`/category/all`)
      .withCredentials()
      .query({testId: this.props.match.params.id})
      .then(res => {
        this.setState({categories: res.body});
      })
      .catch(err => {
        this.setState({isAuthenticated: false, error: err});
      });
  };

  // Sets the current question
  handleSetCurrentQuestion(text, index) {
    this.setState({currentQuestion: {text, index}})
  }

  // Cancels updating a question's text
  handleCancelUpdate() {
    this.setState({currentQuestion: {text: "", index: null}});
  }

  // Updates the question text
  handleUpdateQuestionText(e) {
    const { currentQuestion } = this.state;
    let updatedQuestion = Object.assign({}, currentQuestion);
    updatedQuestion.text = e.target.value
    this.setState({currentQuestion: updatedQuestion});
  }

  // Saves the currentQuestion into the list
  handleSaveQuestionText() {
    const { questions, currentQuestion } = this.state;
    let newQuestions = [...questions];
    newQuestions[currentQuestion.index].text = currentQuestion.text;
    this.setState({questions: newQuestions, currentQuestion: {text: "", index: null}});
  }

    // Sends question array to the server
  async handleSave() {
    await post(`/question/upsert`)
      .send({
        questions: this.state.questions,
        testId: this.props.match.params.id
      })
      .withCredentials()
      .then((res) => {
        this.setState({
          saved: true,
          dialog: {open: false, text: ""},
          error: false
        });
        // Get questions again
        this.getQuestions();
      })
      .catch(err => {
        this.setState({error: err.response.text});
      });
  }

  // Switches a question from "deleted: false" to "deleted: true"
  handleDeleteQuestion(index) {
    const { questions } = this.state;
    let newQuestions = [...questions];
    newQuestions[index].deleted = true;
    this.setState({questions: newQuestions});
  }

  // Updates whether or not the question has "reverse" scoring
  handleUpdateQuestionReverse(e, i) {
    const { questions } = this.state;
    let newQuestions = [...questions];
    newQuestions[i].reversed = e.target.checked;
    this.setState({questions: newQuestions});
  }

  // Updates question grouping
  handleUpdateQuestionCategory(e, i) {
    const { questions } = this.state;
    let newQuestions = [...questions];
    newQuestions[i].categoryId = parseInt(e.target.value);
    this.setState({questions: newQuestions});
  }

  // Adds a new question to the questions array
  addQuestion() {
    const { questions } = this.state;
    const nextQuestions = [...questions, {
      text: "New Question",
      deleted: false,
      reversed: false,
      category: "Collaborating",
      testId: this.props.match.params.id
    }];
    const nextIndex = nextQuestions.length - 1;
    this.setState({
      questions: nextQuestions, 
      currentQuestion: {text: nextQuestions[nextIndex].text, index: nextIndex}
    });
  }
  
  async openDialog(name) {
    if (name === "save") {
      await get(`/user-test/count/completed?testId=${this.props.match.params.id}`)
        .then((response) => {
          if (response.body.numCompleted === 0) {
            this.handleSave();
            return;
          }
          else {
            this.setState({dialog: {
              open: true, 
              text: `${response.body.numCompleted} student(s) completed the test. Saving will reset student answers. Are you sure you wish to continue?`, 
              name
            }});
          }
        });
    }
    if (name === "undo") {
      this.setState({dialog: {
        open: true, 
        text: "Are you sure you want to reset your changes to the categories?", 
        name
      }});    
    }
  }

  closeDialog() {
    this.setState({dialog: {open: false, text: ""}});
  }

  // Deletes user tests before saving
  async handleDeleteAndSave() {
    await post(`/user-test/delete/all?testId=${this.props.match.params.id}`)
    .withCredentials()
    .then(async () => {
      this.handleSave();
    }).catch(err => {
      console.log(err);
      this.setState({error: "There was an error updating the questions."});
    })
  }

  // Requests questions from database again to start from scratch/remove updates.
  async handleUndo() {
    this.getQuestions();
    if (!this.state.error) {
      this.setState({dialog: {open: false, text: ""}, undone: true});
    }
  }
  
  componentDidMount() {
    this.getQuestions();
    this.getCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    // Remove saved message after 2 seconds
    if (!prevState.saved && this.state.saved) {
      setTimeout(() => {
        this.setState({saved: false});
      }, 2000)
    } 
    // Remove undone message after 2 seconds
    if (!prevState.undone && this.state.undone) {
      setTimeout(() => {
        this.setState({undone: false})
      }, 2000)
    } 
  }

  render() {
    const { 
      questions, 
      currentQuestion,
      categories,
      saved,
      undone,
      dialog,
      error
     } = this.state;

    return (
      <div>
        <p>
          Here you can add, edit, and remove questions in your self-test. 
          You can also click drag questions to reorder them - the order they appear below will be the same order students will see them. 
          Be sure to click the "Save" button at the bottom when you are done. If haven't saved and wish to undo your changes, click the "Undo Edits" button.  
        </p>
        {questions.length > 0 ?
          <SortableList 
            helperClass="sortable-item-dragging"
            items={questions} 
            categories={categories}
            currentItem={currentQuestion}
            onSortEnd={this.onSortEnd} 
            handleSetCurrentItem={this.handleSetCurrentQuestion}
            handleCancelUpdate={this.handleCancelUpdate}
            handleUpdateItemText={this.handleUpdateQuestionText}
            handleSaveItemText={this.handleSaveQuestionText}
            handleDeleteItem={this.handleDeleteQuestion}
            handleUpdateItemReverse={this.handleUpdateQuestionReverse}
            handleUpdateItemCategory={this.handleUpdateQuestionCategory}
          /> : <p>This test doesn't have any questions yet.</p> 
        }

        <button onClick={this.addQuestion}>Add Question +</button>
        
        <p>
          <span className={`success-message ${saved ? "" : "hidden"}`}>
            Questions successfully saved.
          </span> 
          <span className={`success-message ${undone ? "" : "hidden"}`}>
            Your updates have been undone.
          </span> 
        </p>

        {dialog.open ? 
          <div className="dialog">
            <span className="warning-text">{dialog.text}</span>
            <br/>
            <button onClick={() => this.closeDialog()}>Cancel</button>
            <button 
              className="green" 
              onClick={() => dialog.name === "save" ? this.handleDeleteAndSave() : this.handleUndo()}>
              Continue
            </button>
          </div>
        : null}

        <div className={`${dialog.open || saved || undone ? "hidden" : ""}`}>
          <button 
            className="save" 
            onClick={() => this.openDialog("save")}>Save</button>
          <button className="btn danger" onClick={() => this.openDialog("undo")}>Undo Edits</button>
        </div>

        <span className={`warning-text ${error ? "" : "hidden"}`} style={{marginTop: "20px"}}>
          {error}
        </span>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default withRouter(connect(mapStateToProps)(DragAndDrop));

