import * as React from "react";
import { get, post } from "superagent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export class Categories extends React.Component<any, any> {
  
  constructor(props) {
    super(props)

    this.state = {
      categories: [],
      error: null,
      currentCategory: {name: "", id: 0},
      dialog: {open: false, text: "", name: ""},
      saved: false,
      undone: false,
    }

    this.getCategories = this.getCategories.bind(this);
    this.addCategory = this.addCategory.bind(this);
    this.saveCategoryName = this.saveCategoryName.bind(this);
    this.updateCategoryName = this.updateCategoryName.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  // Gets categories from DB
  async getCategories() {
    await get(`/category/all`)
    .withCredentials()
    .query({testId: this.props.match.params.id})
    .then(res => {
      if (res.status === 200) {
        this.setState({categories: res.body});        
      }
    })
    .catch(err => {
      this.setState({error: "Error getting categories."});
    });
  };

  // Adds a new category to the categories array
  addCategory() {
    const { categories } = this.state;
    const nextCategories = [...categories, {
      name: "New Category",
      deleted: false,
      testId: this.props.match.params.id
    }];
    const nextIndex = nextCategories.length - 1;
    this.setState({
      categories: nextCategories, 
      currentCategory: {name: nextCategories[nextIndex].name, index: nextIndex}
    });
  }


  // Updates the category name
  updateCategoryName(e) {
    const { currentCategory } = this.state;
    let updatedCategory = Object.assign({}, currentCategory);
    updatedCategory.name = e.target.value
    this.setState({currentCategory: updatedCategory});
  }

  // Saves the currentCategory into the list
  saveCategoryName() {
    const { categories, currentCategory } = this.state;
    let newCategories = [...categories];
    newCategories[currentCategory.index].name = currentCategory.name;
    this.setState({categories: newCategories, currentCategory: {name: "", index: null}});
  }

  // Switches a category from "deleted: false" to "deleted: true"
  handleDelete(index) {
    const { categories } = this.state;
    let newCategories = [...categories];
    newCategories[index].deleted = true;
    this.setState({categories: newCategories});
  }

  // Sends categories array to the server
  async handleSave() {
    await post(`/user-test/delete/all?testId=${this.props.match.params.id}`)
      .withCredentials()
      .then(async () => {
        await post(`/category/upsert`)
          .set('Content-Type', 'application/json')
          .send(JSON.stringify({
            categories: this.state.categories,
          }))
          .withCredentials()
          .then(() => {
            this.setState({
              saved: true,
              dialog: {open: false, text: ""}
            });
            // Get categories again
            this.getCategories();
          }).catch(err => {
            console.log(err);
            this.setState({error: "There was an error updating the categories."});
          });
      }).catch(err => {
        console.log(err);
        this.setState({error: "There was an error updating the questions."});
      })
  }

  // Requests categories from database again to start from scratch/remove updates.
  async handleUndo() {
    this.getCategories();
    if (!this.state.error) {
      this.setState({dialog: {open: false, text: ""}, undone: true});
    }
  }

  async openDialog(name) {

    if (name === "save") {
      await get(`/user-test/count/completed?testId=${this.props.match.params.id}`)
        .then((response) => {
          // If no answers were submitted for this test, ignore and save
          if (response.body.numCompleted === 0) {
            this.handleSave();
            return;
          }
          else {  
            this.setState({dialog: {
              open: true, 
              text: `${response.body.numCompleted} student(s) completed the test. Saving will reset student answers. Are you sure you wish to continue?`, 
              name
            }});
          }
        });
    }
    if (name === "undo") {
      this.setState({dialog: {
        open: true, 
        text: "Are you sure you want to reset your changes to the categories?", 
        name
      }});
    }
  }

  closeDialog() {
    this.setState({dialog: {open: false, text: ""}});
  }

  componentDidMount() {
    this.getCategories();
    this.getCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    // Remove saved message after 2 seconds
    if (!prevState.saved && this.state.saved) {
      setTimeout(() => {
        this.setState({saved: false});
      }, 2000)
    } 
    // Remove undone message after 2 seconds
    if (!prevState.undone && this.state.undone) {
      setTimeout(() => {
        this.setState({undone: false})
      }, 2000)
    } 
  }

  render() {
    const { 
      categories, 
      currentCategory, 
      dialog,
      saved,
      undone,
      error
    } = this.state;

    return (
      <div>
        <p>
          Here you can add, edit, and remove categories. You can assign these categories to questions under the Questions tab.
          These categories will also be displayed in the Results tab, and be shown to students in their results chart.
        </p>
        <ul className="item-list-editor">
          {categories.length > 0 && !error ? 
            categories.map((category, i) => {
              // Only show non-deleted categories
              if (!category.deleted) {
                // Show category as input if editing
                if (currentCategory.index === i) {
                  return (
                    <li key={i}>
                      <input 
                        value={currentCategory.name} 
                        key={i}
                        onChange={e => this.updateCategoryName(e)}
                      />
                      <div>
                        <button onClick={this.saveCategoryName}>Save</button>
                        <button 
                          onClick={() => this.setState({currentCategory: {
                            name: "",
                            index: null
                          }})}>
                          Cancel
                          </button>
                      </div>
                    </li>
                    
                  );
                }
                else {
                  return (
                    <li key={i}>
                      <div className="category-name">{category.name}</div>
                      <div className="options">
                        <button 
                          onClick={() => this.setState({currentCategory: {name: category.name, index: i}})}
                          title="Edit the category text">
                          Edit
                        </button> 
                        <button 
                          onClick={() => this.handleDelete(i)}
                          title="Remove the category">
                          Remove
                        </button>
                      </div>
                    </li>
                  );
                }
              }
            }) : <p>This test doesn't have any categories yet.</p>
          }

          {categories.length === 0 ? <p>You don't have any categories yet.</p> : null}
          
        </ul>

        <button onClick={this.addCategory}>Add Category +</button>

        <p>
          <span className={`success-message ${saved ? "" : "hidden"}`}>
            Categories successfully saved.
          </span> 
          <span className={`success-message ${undone ? "" : "hidden"}`}>
            Your updates have been undone.
          </span> 
        </p>

        {dialog.open ? 
          <div className="dialog">
            <span className="warning-text">{dialog.text}</span>
            <br/>
            <button onClick={() => this.closeDialog()}>Cancel</button>
            <button 
              className="green" 
              onClick={() => dialog.name === "save" ? this.handleSave() : this.handleUndo()}>
              Continue
            </button>
          </div>
        : null}

        <div className={`${dialog.open || saved || undone ? "hidden" : ""}`}>
          <button 
            className="save" 
            onClick={() => this.openDialog("save")}>Save</button>
          <button className="btn danger" onClick={() => this.openDialog("undo")}>Undo Edits</button>
        </div>

        <span className="warning-text" style={{display: error ? "initial" : "none"}}>{error}</span>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};


export default withRouter(connect(mapStateToProps)(Categories));
