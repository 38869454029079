import * as Redux from "redux";
import { createStore, applyMiddleware, compose } from "redux";
import promise from "redux-promise";
import logger from "redux-logger";
import reducer from "../reducers";
import { throttle } from "lodash";

import { initialState as questions } from "../reducers/questions";
import { initialState as answers } from "../reducers/answers";

let thunk = require("redux-thunk").default;
let configureStore: {(): Redux.Store<any>} = undefined;

declare let window: any;
declare let module: any;

const defaultState = {
  questions,
  currentQuestion: -1,
  answers
};

configureStore = () => {

  let store = createStore(
    reducer,
    defaultState,
    compose(
      process.env.NODE_ENV === "development" ?
        applyMiddleware(thunk, promise, logger) :
        applyMiddleware(thunk, promise),
      typeof window !== "undefined" && window.devToolsExtension ? window.devToolsExtension() : f => f
    )
  );

  if (module.hot && process.env.NODE_ENV === "development") {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers", () => {
      const nextRootReducer = require("../reducers").default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
