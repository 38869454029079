import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateCurrentQuestion } from "../../actions/currentQuestion";
import { submitAnswers } from "../../actions/answer";

export class StudentNav extends React.Component<any, any> {

  state = {
    navIsOpen: false,
    questions: []
  };

  constructor(props) {
    super(props);
    this.openOrCloseNav = this.openOrCloseNav.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
  }

  // Closes nav if clicked anywhere other than the question menu
  openOrCloseNav(e) {
    const { navIsOpen } = this.state;
    const classes = e.target.classList;
    if (navIsOpen && !classes.contains("question-choice")) {
      this.setState({navIsOpen: false});
    }
    if (classes.contains("current-question")) {
      this.setState({navIsOpen: !navIsOpen});
    }
  }

  submitAnswers() {
    const { dispatch, questions, answers } = this.props;
    dispatch(submitAnswers({
      questions: questions.questions, 
      answers: answers.answers, 
      testId: this.props.match.params.id
    }));
  }

  componentDidMount() {
    document.addEventListener("click", this.openOrCloseNav);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.openOrCloseNav);
  }

  render() {
    const { questions, currentQuestion, dispatch, totalQuestions, answers } = this.props;

    return (
      <div className="navigation">

        <div className="prev-next">

          {currentQuestion >= 0  ?
              <button
                className="prev"
                onClick={() => dispatch(updateCurrentQuestion({questionId: currentQuestion - 1}))}
                style={{marginRight: currentQuestion !== totalQuestions -1 ? "20px" : "0px"}}>
                <i className="fa fa-arrow-left"/>{currentQuestion === 0 ? " Home" : " Previous Question"}
              </button>
              : null}

          {currentQuestion !== questions.totalQuestions - 1 ?
              <button 
              disabled={!answers.answers[currentQuestion] && currentQuestion !== -1} 
              className={`next ${!answers.answers[currentQuestion] && currentQuestion !== -1 ? "disabled" : ""}`} 
              onClick={() => dispatch(updateCurrentQuestion({questionId: currentQuestion + 1}))}>
              {currentQuestion === -1 ? "Start " : "Next Question "}<i className="fa fa-arrow-right"/>
            </button>
           :
            <button 
              className={`submit ${(!answers.answers[currentQuestion] && currentQuestion !== -1) || answers.submitted ? "disabled" : "green"}`} 
              onClick={() => this.submitAnswers()}>
              {answers.submitted ? `Submitted ` : `Submit`}
              {answers.submitted ? <i className="fa fa-check"/> : ""}
            </button>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questions, currentQuestion, totalQuestions, answers } = state;
  return { questions, currentQuestion, totalQuestions, answers };
};

export default withRouter(connect(mapStateToProps)(StudentNav));
