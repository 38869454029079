import { merge } from "lodash";
import { post } from "superagent";

export const GET_ANSWER_STATS = "GET_ANSWER_STATS";
export const MAKE_CHOICE = "MAKE_CHOICE";
export const SUBMIT_ANSWERS = "SUBMIT_ANSWERS";
export const SET_ANSWERS = "SET_ANSWERS";


export interface Answer {
  questionId: number, 
  choice: number,
  categoryId: number,
  reversed: boolean
}

export let answer = (options: { answer: Answer } ) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, {
      type: MAKE_CHOICE,
      answer: options.answer
    }));
  };
};

export let submitAnswers = (options: {
  questions: [any], 
  answers: {[key: number]: Answer}, 
  testId: number
}) => {
  return async (dispatch, getState) => {
    await post(`/user-test/new`)
    .send({
      answers: options.answers,
      testId: options.testId
    })
    .then(() => {
      dispatch(merge({}, {
        type: SUBMIT_ANSWERS,
        success: true
      }));
    }).catch((err) => {
      console.log("error: ", err);
    });
  };
};

export let setAnswers = (options: {
  submitted: boolean,
  answers: {
    [key: number]: Answer
  }}) => {
  return async (dispatch, getState) => {
    await dispatch(merge({}, {
      type: SET_ANSWERS,
      answers: options.answers,
      submitted: options.submitted
    }));
  };
};
