import { UPDATE_CURRENT_QUESTION } from "../actions/currentQuestion";
import { SET_QUESTIONS } from "../actions/questions";

const initialState = -1;

export let reducer = (state: number = initialState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_QUESTION: {
      return action.questionId;
    }
    case SET_QUESTIONS: {
      return -1;
    }
    default:
      return state;
  }
};

export default reducer;
