import * as React from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import { get } from "superagent";

import { createChart } from "../../shared/helpers";

export class StudentResults extends React.Component<any, any> {

  state = {
    error: null,
  }

  async componentDidMount() {
    // Get user test results
    await get(`/user-test/results`)
      .query({testId: this.props.match.params.id})
      .then((res) => {
        createChart(res.body.scores, "user-results", res.body.categoryNames);
      })
      .catch((err) => {
        console.log("error:", err)
        this.setState({error: "There was an error getting test results."});
      });

    // Get all test results
    await get(`/user-test/results/all`)
    .query({testId: this.props.match.params.id})
    .then((res) => {
      createChart(res.body.scores, "total-results", res.body.categoryNames);
    })
    .catch((err) => {
      console.log("error:", err)
      this.setState({error: "There was an error getting all test results."});
    });

  }

  render() {
    const { error } = this.state;
    return (
      <div className={"result-container"}> 
        <div className="nav">
          <ul>
            <li>
              <NavLink exact to={`/`} activeClassName="active">
                <i className="fa fa-arrow-left"/> &nbsp;Back to All Tests
              </NavLink>
            </li>
          </ul>
        </div>
        <div>
          <h2 className={"result-text"}>Your Results:</h2>
          <canvas id="user-results" style={{maxWidth: "700px"}}></canvas>
          <br/><br/>
        </div>
        <div>
          <h2 className={"result-text"}>Average Student Results:</h2>
          <canvas id="total-results" style={{maxWidth: "700px"}}></canvas>
          <br/><br/> 
        </div>
        <span className="warning-text" style={{display: error ? "initial" : "none"}}>{error}</span>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { currentQuestion, answers, questions } = state;
  return {
    currentQuestion,
    answers,
    questions
  };
};

export default withRouter(connect(mapStateToProps)(StudentResults));
