import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { answer } from "./../../actions/answer";

import Button from "./button";
import Radio from "./radio";
import Results from "./results";

export class Question extends React.Component<any, any> {

  state = {
    questionText: null,
    questionId: null,
  };

  answerKey = [
    {
      text: '(1)',
      value: 1
    },
    {
      text: '(2)',
      value: 2
    },
    {
      text: '(3)',
      value: 3
    },
    {
      text: '(4)',
      value: 4
    },
    {
      text: '(5)',
      value: 5
    },
    {
      text: '(6)',
      value: 6
    },
    {
      text: '(7)',
      value: 7
    },
  ];

  constructor(props) {
    super(props);
    this.onAnswer = this.onAnswer.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    if (this.props.currentQuestion !== prevProps.currentQuestion) {
      const { currentQuestion, questions, answers, dispatch}  = this.props;

      this.setState({
        questionId: currentQuestion,
        questionText: questions.questions[currentQuestion].text,
      });

    }

  }

  componentDidMount(): void {
    const { questions, currentQuestion, answers } = this.props;
    this.setState({
      questionId: currentQuestion,
      questionText: questions.questions[currentQuestion].text,
    });

  }

  onAnswer(number): void {
    const { dispatch, answers, currentQuestion, questions } = this.props;
    const { questionId } = this.state;
    if (answers.submitted) {
      return;
    }

    dispatch(answer({answer: { 
      questionId, 
      choice: number, 
      categoryId: questions.questions[currentQuestion].categoryId,
      reversed: questions.questions[currentQuestion].reversed
    }}));
  }

  render() {
    const { currentQuestion, questions, answers } = this.props;
    const { questionText } = this.state;
    const currentAnswer = answers.answers[currentQuestion];
    const currentChoice = currentAnswer ? currentAnswer.choice : null;

    return (
      <div>
        <h2>{questionText ? questionText : "no data"}</h2>

        <div className="multiple-choice">
          <div className="answer-key" >
            <div >
              Strongly Disagree
            </div>
            <div className="strongly-disagree">
              Strongly Agree
            </div>
          </div>
          {
            this.answerKey.map((answer) => {
              return (
                <div 
                  key={answer.value} 
                  className="radio disabled" 
                  onClick={() => this.onAnswer(answer.value)}>
                    <Radio 
                      submitted={answers.submitted} 
                      selected={currentChoice === answer.value}/>
                  <div className="text">{answer.text}</div>
                </div>
              );

            })
          }


        </div>

        <br/>
        {(currentQuestion === questions.questions.length - 1) && answers.submitted ?
          <div>
            <div className="compare-answer">
              <Button questionId={currentQuestion} choiceMade={answers.answers[currentQuestion.id]}/>
            </div>
            {questions.showResults ? <Results /> : null}
          </div>
          : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { questions, currentQuestion, questionText, questionId, currentChoice, answers } = state;
  return {
    questions,
    currentQuestion,
    questionText,
    questionId,
    currentChoice,
    answers
  };
};


export default withRouter(connect(mapStateToProps)(Question));
