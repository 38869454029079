import * as React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

const SortableItem = SortableElement(({
  item, 
  categories,
  currentItem, 
  itemIndex,
  setCurrentItem,
  cancelUpdate,
  updateItemText,
  saveItemText,
  deleteItem,
  updateItemReverse,
  updateItemCategory
}) => {

  const isQuestionList = item.text ? true : false;
  const text = isQuestionList ? item.text : item.name;
  const itemHasCategory = categories.find(c => c.id === item.categoryId);

  // Show question as input if editing
  if (currentItem.index === itemIndex) {
    return (
      <li key={itemIndex} className="sortable-item">
        <input 
          value={isQuestionList ? currentItem.text : currentItem.name} 
          key={itemIndex}
          onChange={e => updateItemText(e)}
        />
        <div>
          <button onClick={() => saveItemText()}>Save</button>
          <button onClick={() => cancelUpdate()}>Cancel</button>
        </div>
      </li> 
    );
  }

  else {
    return (
      <li key={itemIndex} className="sortable-item">
        <div className="question-text">{text}</div>
        <div className="options">
          <button 
            onClick={() => setCurrentItem(text, itemIndex)}
            title="Edit the question text">
              Edit
          </button> 
          <button 
            onClick={() => deleteItem(itemIndex)}
            title="Remove the question">
            Remove
          </button>

          {isQuestionList ?
            <div 
              className="reverse-selection"
              title="Checking this will cause the question to be scored in reverse (E.G. 'Strongly agree' would be scored as low instead of high)">
              Reversed
              <input 
                type="checkbox"
                defaultChecked={item.reversed}
                onClick={(e) => updateItemReverse(e, itemIndex)}
              />
            </div> : null }

          {isQuestionList ? 
            <select 
              name={!itemHasCategory ? "" : item.categoryId}
              className="category"
              title="Select the category this question belongs to"
              onChange={(e) => updateItemCategory(e, itemIndex)}
              value={`${!itemHasCategory ? "" : item.categoryId}`}>
              {/* 
                Displays an extra option when the question does not yet have a category
                or the category was deleted
              */}
              {!itemHasCategory ? 
                <option hidden key="null" value="null"></option>
                : null
              }
                            {
              categories.map((category) => {
                return (
                  <option key={category.id} value={category.id}>{category.name}</option>
                )
              })}
            </select> : null
          }
        </div>
      </li>
    );
  }
});

const SortableList = SortableContainer(({
  items, 
  currentItem,
  categories,
  handleSetCurrentItem,
  handleCancelUpdate,
  handleUpdateItemText,
  handleSaveItemText,
  handleDeleteItem,
  handleUpdateItemReverse,
  handleUpdateItemCategory
}) => {
  return (
    <ul className="item-list-editor">
      {items.map((item, index) => {
        if (!item.deleted) {
          return (
            <SortableItem 
              key={`item-${index}`}
              disabled={currentItem.index !== null}
              index={index}
              itemIndex={index}
              item={item}
              categories={categories}
              currentItem={currentItem}
              setCurrentItem={handleSetCurrentItem}
              cancelUpdate={handleCancelUpdate}
              updateItemText={handleUpdateItemText}
              saveItemText={handleSaveItemText}
              deleteItem={handleDeleteItem}
              updateItemReverse={handleUpdateItemReverse}
              updateItemCategory={handleUpdateItemCategory}
            />
          )}
      })}
    </ul>
  );
});

export default SortableList;