import {SET_QUESTIONS, SET_DISPLAY_RESULT} from "../actions/questions";

export interface Questions {
  resultLoaded: Array<any>,
  questions: Array<any>,
  showResults: Array<any>,
}

export const initialState = {
  resultLoaded: [],
  questions: [],
  showResults: []
};

export let reducer = (state: Questions = initialState, action) => {
  switch (action.type) {
    case SET_QUESTIONS: {
      let nextState = Object.assign({}, state, {
        questions: action.questions,
        totalQuestions: action.questions.length,
        resultLoaded: [],
        showResults: action.showResults,
        loadedAt: action.loadedAt
      });
      return nextState;
    }
    case SET_DISPLAY_RESULT: {
      let nextState = Object.assign({}, state, {showResults: action.showResults});
      return nextState;
    }
    default:{
      return state;
    }
  }
};

export default reducer;
