import * as React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { setDisplayResult } from "../../actions/questions";

export class Button extends React.Component<any, any> {

  state = {
    showingMessage: false,
    loading: false,
    error: false,
    submitted: false,
    choiceMade: false
  };

  constructor(props) {
    super(props);
    this.handleCompare = this.handleCompare.bind(this);
    this.getResults = this.getResults.bind(this);
    this.handleClickWithNoChoice = this.handleClickWithNoChoice.bind(this);
  }

  componentDidMount(): void {
    const { answers, currentQuestion } = this.props;

    if (answers.answers[currentQuestion] !== null) {
      this.setState({choiceMade: true});
    }
  }

  componentDidUpdate(prevProps, prevState) {

    const { answers, currentQuestion } = this.props;
    const { choiceMade } = this.state;

    // If an answer has been made and the error message is showing,
    // Remove the error message
    if (!prevProps.choiceMade && this.props.choiceMade && this.state.showingMessage) {
      this.setState({showingMessage : false});
    }

    if (answers.answers[currentQuestion] !== null && !choiceMade) {
      this.setState({choiceMade: true});
    }
  };

  // Send answer to database and return total answers
  async getResults() {
    const { dispatch, currentQuestion } = this.props;
    await dispatch(setDisplayResult(currentQuestion));
    this.setState({loading: false})
  }

  handleCompare() {
    const { choiceMade } = this.state;
    // If a choice was made and the user did not submit this Question yet:
    if (choiceMade && !this.state.submitted) {
      
      this.setState({loading: true});
      this.getResults();

      // Turn the "select answer" message off if it was showing before
      if (this.state.showingMessage) {
        this.setState({ showingMessage: false });
      }
    }
  }

  handleClickWithNoChoice(e) {
    e.preventDefault();
    this.setState({showingMessage: true})
  }

  render() {
    const { choiceMade } = this.state;
    const { questions } = this.props;

    return (
      <div>

        {!this.state.submitted && !this.state.loading ?
          
          <Link to={`${this.props.match.params.id}/results`}>
            <button 
              className={`green ${questions.showResults ? "hidden" : ""}`}
              onClick={(e) => choiceMade ? this.handleCompare() : this.handleClickWithNoChoice(e)}>
                Show Results
            </button>
          </Link> : null}

        {this.state.showingMessage ?
          <div className="select-answer-message">Please select an answer.</div>
        : null}

        {this.state.loading ? 
          <div className="loading">
            <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
            <span className="sr-only">Loading...</span>
          </div> : null}

      </div>
    );
  }
}

let mapStateToProps = (state, ownProps) => {
  const { questions, currentQuestion, answers } = state;
  return {
    questions, currentQuestion, answers
  };
};

export default withRouter(connect(mapStateToProps)(Button));
