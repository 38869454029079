import { MAKE_CHOICE } from "../actions/answer";
import { GET_ANSWER_STATS, SUBMIT_ANSWERS, SET_ANSWERS } from "../actions/answer";
import { Answer } from "../actions/answer";

interface Answers {
  answers: {
    [key: number]: Answer
  },
  total: null|number,
  submitted: boolean
}

export const initialState = {
  answers: {},
  total: null,
  submitted: false
};

export let reducer = (state: Answers = initialState, action) => {
  switch (action.type) {
    case GET_ANSWER_STATS: {
      let nextState = Object.assign({}, state, {total: action.total });
      return nextState;
    }
    case SUBMIT_ANSWERS: {
      let nextState = Object.assign({}, state, {submitted: true});
      return nextState;
    }
    case MAKE_CHOICE: {
      let nextState = Object.assign({}, state);
      nextState.answers[action.answer.questionId] = action.answer;
      return nextState;
    }
    case SET_ANSWERS: {
      let nextState = Object.assign({}, initialState);
      nextState.answers = action.answers;
      nextState.submitted = action.submitted;
      return nextState;
    }
    default:
      return state;
  }
};

export default reducer;
