import * as React from "react";
import { get, post } from "superagent";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

export class InstructorView extends React.Component<any, any> {
  
  _importsWindowEl;

  state = {
    tests: [],
    shareableTests: [],
    error: false,
    showingImports: false,
    selectedTestName: null,
    selectedTestId: null
  }

  constructor(props) {
    super(props);
    this.getAllTests = this.getAllTests.bind(this);
    this.createNewTest = this.createNewTest.bind(this);
    this.importTest = this.importTest.bind(this);
  }

  // Makes a call to the test API to create a new blank test
  async createNewTest() {
    await post(`/test/new`)
    .withCredentials()
    .then(res => {
      this.setState({tests: res.body !== null ? res.body : []});
    })
    .catch(err => {
      this.setState({error: err});
    });
  }

    // Makes a call to the test API to import an existing test
    async importTest(testId) {
      await post(`/test/import`)
      .query({testId})
      .withCredentials()
      .then(res => {
        this.setState({tests: res.body, showingImports: false});
      })
      .catch(err => {
        this.setState({error: err});
      });
    }

  // Makes a call to the test API to get an array of all tests
  async getAllTests() {
    // Get all tests for this course
    await get(`/test/all`)
    .withCredentials()
    .then(res => {
      this.setState({tests: res.body !== null ? res.body : []});
    })
    .catch(err => {
      console.log(err);
      this.setState({error: "There was an error getting tests for this course."});
    });
    // Get all shareable tests for importing
    await get(`/test/all-shareable`)
    .withCredentials()
    .then(res => {
      this.setState({shareableTests: res.body !== null ? res.body : []});
    })
    .catch(err => {
      console.log(err);
      this.setState({error: "There was an error getting shareable tests."});
    });
  }

  componentDidMount() {
    this.getAllTests();
  }

  componentDidUpdate(prevProps, prevState) {
    const { showingImports } = this.state;
    // Position the imports window in the center of the screen
    if (!prevState.showingImports && showingImports) {
      const width = this._importsWindowEl.offsetWidth;
      const height = this._importsWindowEl.offsetHeight;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      this._importsWindowEl.style.left = `${(windowWidth/2) - (width/2)}px`;
      this._importsWindowEl.style.top = `${(windowHeight/2) - (height/2)}px`;
    }
  }

  render() {
    const { 
      tests, 
      showingImports, 
      shareableTests, 
      selectedTestName,
      selectedTestId
    } = this.state;
    
    return (
      <div id="instructor-view">
        <h1>Self-Test Creator</h1>
        <p>Click on a test below to edit or view student answers.</p>
        {tests.length > 0 ? 
          <ul className="tests">
            {tests.map((test, i) => {
              return (
                <Link key={i} to={`/test/${test.id}/settings`}>
                  <li>
                    <h3>{test.title}</h3>
                    <p>{test.description}</p>
                    <p>Questions: {test.numQuestions}</p>
                    <span className={test.enabled ? "enabled" : "disabled"}>
                      {test.enabled ? 
                      <span><i className="fa fa-check" aria-hidden="true"></i> Enabled</span> : 
                      <span><i className="fa fa-times" aria-hidden="true"></i> Disabled</span>}  
                    </span>                
                  </li>
                </Link>
              );
            })}
          </ul> 
        : null}
        <button 
          className="btn btn-default"
          style={{marginRight: "15px"}}
          onClick={() => this.createNewTest()}>
          Add New Test +
        </button>
        <button 
          className="btn btn-default" 
          onClick={() => this.setState({showingImports: true})}>
          Import Test
        </button>

        {showingImports ? 
          <div className="overlay" onClick={() => this.setState({showingImports: false})}/> 
        : null}

        <div 
          className="imports-window"
          style={{display: showingImports ? "initial" : "none"}} 
          ref={el => this._importsWindowEl = el}>
          <h2>Import Test</h2>
          <p>Select a test from the list of available tests below to copy it to this course.</p>
          <div className="shareable-tests-container">
            {shareableTests.length > 0 ? 
              <ul className="shareable-tests">
                {shareableTests.map((test, i) => {
                  return (
                    <li onClick={e => this.setState({
                        selectedTestId: test.id, 
                        selectedTestName: test.name
                      })} key={i}>
                      <div className="title">{test.title}</div>
                      <div className="description"><p>{test.description}</p></div>
                    </li>
                  );
                })}
              </ul>
            : "There are no shareable tests yet."}<ul></ul>
          </div>
          <button 
            className="btn btn-default"
            onClick={() => this.setState({showingImports: false})}>
            Cancel
          </button>
          
        </div>

        {selectedTestName !== null ? <div className="overlay"/> : null }

        { selectedTestName !== null ? 
          <div className="popup">
            <p>Confirm import of {selectedTestName ? selectedTestName : '"Untitled"'}? Importing will create a copy of {selectedTestName ? selectedTestName : '"Untitled"'} for your course.</p>
            <button 
              className="btn green"
              onClick={() => {
                this.importTest(selectedTestId);
                this.setState({
                selectedTestId: null, 
                selectedTestName: null, 
                showingImports: false
              })}}>
              Confirm
            </button>
            <button 
              className="btn"
              onClick={() => this.setState({selectedTestId: null, selectedTestName: null})}>
              Cancel
            </button>
          </div> : null }
 
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};


export default withRouter(connect(mapStateToProps)(InstructorView));
