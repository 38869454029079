import * as React from "react";
import { connect } from "react-redux";
import { post } from "superagent";
import { BrowserRouter as Router, Route } from "react-router-dom";

import StudentView from "./components/student/studentView";
import InstructorView from "./components/instructor/instructorView";
import InstructorNav from "./components/instructor/instructorNav";
import Settings from "./components/instructor/settings";
import QuestionEditor from "./components/instructor/questionEditor";
import Categories from "./components/instructor/categories";
import Test from "./components/student/test";
import StudentResults from "./components/student/results";
import InstructorResults from "./components/instructor/results";

interface stateTypes {
  role: null|"instructor"|"student",
  error: null|string
}

export class App extends React.Component<any, any> {

  state: stateTypes = {
    role: null,
    error: null
  }

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    // Validate LTI
    await post(`/validate-user/`).then((res) => {
      this.setState({role: res.body.role})
      return true;
    })
    .catch((err) => {
      if (err.status === 401) {
        this.setState({role: null});
      }
      else {
        this.setState({error: err.message});
      }
    });
  }


  render() {
    const { role } = this.state;

    // Return student Routes
    if (role === "student") {
      return (
        <div id="content">
          <Router>
            <Route exact path={"/"} component={StudentView}/>
            <Route exact path={"/test/:id"} component={Test}/>
            <Route exact path={"/test/:id/results"} component={StudentResults}/>
          </Router>`
        </div>
      );
    }

    if (role === "instructor") {
      return (
        <div id="content">
          <div id="admin">
            <Router>
              <Route exact path={"/"} component={InstructorView}/>
              <Route path={"/test/:id"}>
                <Route path={"/test/:id/"} component={InstructorNav}/>
                <Route exact path={"/test/:id/"} component={Settings}/>
                <Route path={"/test/:id/settings"} component={Settings}/>
                <Route path={"/test/:id/questions"} component={QuestionEditor}/>
                <Route path={"/test/:id/categories"} component={Categories}/>
                <Route path={"/test/:id/results"} component={InstructorResults}/>
              </Route>

            </Router>
          </div>
        </div>
      );
    }

    else {
      return (
        <div>Unauthorized</div>
      );
    }
    
  }
}

let mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps)(App);
